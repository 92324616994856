<template>
    <div class='header'>
        <span class="pop-notes-title" >Trending Notes</span>
        <SelectButton v-model="range" :options="ranges" dataKey="value" class='date-range-selector'>
            <template #option="slotProps">
                {{slotProps.option.value }}
            </template>
        </SelectButton>
    </div>
    <FeedActivityList :loader="loadNotes" ref="activityList" templateForComponent='Popular Notes'/>
</template>

<script>
import FeedService from '../../service/FeedService';
import FeedActivityList from './FeedActivityList.vue';
import SelectButton from 'primevue/selectbutton';

import EventBus from '../../event-bus';

export default {
    components: {
        FeedActivityList,SelectButton

    },

    created() {
        EventBus.off('update-comments-from-popular-notes-activity');
        EventBus.on('update-comments-from-popular-notes-activity', (post) => {
            let found = this.$refs.activityList.feed.filter(a => a.id === post.activity.id);

            if (found[0]) {
                this.$refs.activityList.onCommentPosted(found[0]);
            }
        })
    },
    data() {
        return {
            notes: [],
            range: {value: "1M"},
            ranges: [{ value: "1W"}, { value: "1M"}, {value: "1Y"}],
            
        };
    },
   watch: {
       range() {
           if( this.$refs.activityList) {
               this.$refs.activityList.refreshFeed();
           }
       }
   },

    methods: {
        loadNotes(currentFeedItems) {
            
            return FeedService.getPopularNotes(5, currentFeedItems.length, this.range.value);
        },

       
    },
};
</script>

<style scoped>
.header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
.header .pop-notes-title {
    color: #32364e;
    font-size: 24px;
    font-weight: bold;
}
.header .date-range-selector {
    margin-left: auto;
}

::v-deep(.date-range-selector .p-button) {
    padding: 4px;
    border-radius: 4px;
    border: none;
    margin-right: 4px;
}
::v-deep(.date-range-selector .p-button:last-child) {
    margin-right: 0px;
}
::v-deep(.date-range-selector .p-button:not(.p-disabled):not(.p-highlight):hover) {
    background: #32364e;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
}
::v-deep(.date-range-selector .p-button.p-highlight){
    background-color: #32364e;
    border-radius: 4px;
}
::v-deep(.date-range-selector .p-button:focus),
::v-deep(.date-range-selector .p-button.p-highlight:focus){
    box-shadow: none;
}

.feed-listing {
    border-top: 1px solid rgb(226, 226, 226);
}


</style>